import { createStore } from "vuex";
import consent from "./modules/consent";
import register from "./modules/register";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    consent,
    register,
  },
});

const m = {
  default: "พบข้อผิดพลาด กรุณาเข้าใช้งานใหม่ภายหลัง หรือติดต่อเจ้าหน้าที่",
  noPermission: "ไม่พบสิทธิ์เข้าถึงส่วนนี้ กรุณาติดต่อผู้ดูแลระบบ",
  noAccess: "ไม่พบสิทธิ์การเข้าใช้ กรุณาติดต่อผู้ดูแลระบบ",
  noAccessPassword:
    "ไม่พบข้อมูลตรงกับระบบ กรุณาตรวจสอบข้อมูล หรือติดต่อผู้ดูแลระบบ",
  otpFail: "รหัส OTP ไม่ถูกต้อง กรุณาตรวจสอบข้อมูล หรือติดต่อผู้ดูแลระบบ",
  validateLoginFail:
    "ไม่พบข้อมูลตรงกับระบบ กรุณาตรวจสอบข้อมูล หรือติดต่อผู้ดูแลระบบ",
  processFail:
    "ไม่สามารถทำรายการได้ในขณะนี้ กรุณาทำรายการใหม่ภายหลัง หรือติดต่อผู้ดูแลระบบ",
};

export default (code = 100) => {
  const message =
    {
      100: m.default,
      101: m.noAccess,
      102: m.noAccess,
      103: m.noAccess,
      104: m.noAccess,
      105: m.noAccess,
      106: m.noAccess,
      107: m.noAccess,
      108: m.noAccessPassword,
      109: m.default,
      110: m.default,
      111: m.default,
      112: m.default,
      113: m.otpFail,
      114: m.default,
      115: m.default,
      116: m.default,
      117: m.default,
      118: m.default,
      119: m.default,
      120: m.default,
      121: m.default,
      122: m.default,
      123: m.validateLoginFail,
      124: m.default,
      125: m.processFail,
      126: m.processFail,
      127: m.processFail,
      128: m.processFail,
      129: m.processFail,
      130: m.processFail,
      131: m.processFail,
      132: m.processFail,
    }[code] || null;

  return { message, code };
};

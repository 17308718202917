<template>
  <!-- <transition :name="transitionName" appear mode="out-in"> -->
  <!-- <component :is="useLayout"> -->
  <div class="appLayout" id="appLayout">
    <!-- <slot /> -->
    <component :is="layout">
      <slot />
    </component>
    <ConsentDialog />
  </div>
  <!-- </component> -->
  <!-- </transition> -->
</template>

<script>
const defaultLayout = "DefaultLayout";
import DefaultLayout from "@/layouts/DefaultLayout";
import SidebarLayout from "@/layouts/SidebarLayout";
import ConsentDialog from "@/components/ConsentDialog";

export default {
  name: "AppLayout",
  components: { DefaultLayout, SidebarLayout, ConsentDialog },
  computed: {
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
  },
  data() {
    return {
      transitionName: "slide-left",
      useLayout: DefaultLayout,
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler(to) {
        try {
          const component = await import(`@/layouts/${to.meta.layout}.vue`);
          this.useLayout = component?.default || DefaultLayout;
        } catch (e) {
          this.useLayout = DefaultLayout;
        }
        // const IsItABackButton = window.popStateDetected;
        // window.popStateDetected = false;
        // if (IsItABackButton) {
        //   if (from.meta.tt === false) {
        //     this.transitionName = "";
        //     return;
        //   }
        //   this.transitionName = "slide-right";
        //   return;
        // }
        // if (to.meta.tt === true) {
        //   this.transitionName = "slide-left";
        //   return;
        // }
        // if (to.meta.tt === false) {
        //   this.transitionName = "";
        //   return;
        // }
        // const toDepth = to.path.split("/").length;
        // const fromDepth = from.path.split("/").length;
        // if (toDepth == fromDepth) {
        //   this.transitionName = "";
        //   return;
        // }
        // this.transitionName =
        //   toDepth > fromDepth ? "slide-left" : "slide-right";
      },
    },
  },
  mounted() {
    window.popStateDetected = false;
    window.addEventListener("popstate", () => {
      if (this.$route.name) {
        window.popStateDetected = true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.appLayout {
  height: 100%;
}
.slide-left-enter,
.slide-right-leave-active {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}

.slide-left-leave-active {
  z-index: 10;
  background: #fff;
  width: 100%;
  height: 100%;
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);

  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

import jwtDecode from "jwt-decode";

const keysRole = {
  VANSALES: "shop.vansales",
  RETAIL: "shop.admin",
  AREA_PROMOTER: "area.promoter",
};

export default () => {
  let mapResult = {};
  const jwtData = jwtDecode(window.$apiToken);
  const jwtRoleKey = jwtData.r[0].key;
  Object.keys(keysRole).map((key) => {
    mapResult[key] = keysRole[key] === jwtRoleKey;
    if (mapResult[key]) {
      mapResult["default"] = key;
    }
  });
  return mapResult;
};

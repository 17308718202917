import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/main.scss";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/vue";
import { createPinia } from "pinia";

const app = createApp(App);

if (process.env.VUE_APP_MODE === "production") {
  Sentry.init({
    app,
    dsn: "https://a9e3ca641def4b90847fccdc4874cb29@o349622.ingest.sentry.io/6077080",
    release: "chang-tt-frontend@" + process.env.VUE_APP_COMMIT_REF,
    environment: process.env.VUE_APP_MODE,
  });
}

// Handle all Vue errors
// app.config.errorHandler = (e) => {
//   console.log("=== app error:", e);
// };

window.$pushEvent = (c, a, n) => {
  try {
    window["_paq"].push(["trackEvent", c, a, n]);
  } catch (error) {
    console.log(error);
  }
};

const swal = Swal.mixin({
  confirmButtonColor: "#0e783d",
  cancelButtonText: "ปิด",
  confirmButtonText: "ยืนยัน",
  heightAuto: false,
  scrollbarPadding: false,
});

window.Swal = swal;
app.config.globalProperties.Swal = swal;
app.config.globalProperties.liff = window.liff;

app.use(router).use(store).use(createPinia()).mount("#app");

<template>
  <div class="sidebarLayout">
    <div id="sidenav" :class="[{ openNav }, 'sidenav']">
      <div class="closebtn" @click="openNav = false">&times;</div>
      <div class="wrapLogo">
        <img width="120" src="@/assets/logo.webp" alt="" />
      </div>
      <template v-if="role.RETAIL">
        <div class="item" @click="routeTo({ name: 'profile' })">
          ข้อมูลร้านค้า
        </div>
        <div class="item" @click="routeTo({ name: 'events' })">กิจกรรม</div>
        <div class="item" @click="routeTo({ name: 'shop-qr' })">QR ร้านค้า</div>
        <div class="item" @click="routeTo({ name: 'retail-contact-us' })">
          ข้อเสนอแนะ
        </div>
        <div class="item" @click="routeTo({ name: 'shop-change-phone' })">
          เปลี่ยนเบอร์โทรศัพท์
        </div>
      </template>
      <template v-if="role.VANSALES">
        <div class="item" @click="routeTo({ name: 'vansales-profile' })">
          ข้อมูลรถเร่
        </div>
        <div class="item" @click="routeTo({ name: 'vansales-qr' })">
          QR รถเร่
        </div>
        <div class="item" @click="routeTo({ name: 'vansales-shops' })">
          ร้านค้าปลีกที่ดูแล
        </div>
        <div class="item" @click="routeTo({ name: 'vansales-verify-shop' })">
          ยืนยันตัวตนร้านค้า
        </div>
        <!-- <div class="item" @click="routeTo({ name: 'list-contact-us' })">
          ข้อเสนอแนะ
        </div> -->
      </template>
      <template v-if="role.AREA_PROMOTER">
        <div class="item" @click="routeTo({ name: 'area-promoter-profile' })">
          ข้อมูลพนักงาน
        </div>
        <div class="item" @click="routeTo({ name: 'area-promoter-qr' })">
          QR พนักงาน
        </div>
        <div class="item" @click="routeTo({ name: 'area-promoter-shops' })">
          ร้านค้าปลีกที่ดูแล
        </div>
        <div
          class="item"
          @click="routeTo({ name: 'area-promoter-verify-shop' })"
        >
          ยืนยันตัวตนร้านค้า
        </div>
        <div class="item" @click="routeTo({ name: 'list-contact-us' })">
          ข้อเสนอแนะ
        </div>
        <div
          class="item"
          @click="
            routeTo({
              name: 'review-stamps',
            })
          "
        >
          Area Promoter CRM
        </div>
      </template>
      <div class="item" @click="routeTo({ name: 'user-consents' })">
        ข้อมูลการยินยอม
      </div>
    </div>
    <span
      class="openIcon"
      style="font-size: 30px; cursor: pointer"
      @click="openNav = true"
    >
      <img src="@/assets/hamburger.svg" />
    </span>

    <slot />
  </div>
</template>

<script>
import permission from "@/utils/permission";
export default {
  data() {
    return {
      openNav: false,
    };
  },
  computed: {
    role: () => permission(),
  },
  methods: {
    routeTo(routeTo) {
      this.$router.push(routeTo);
      this.openNav = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.sidebarLayout {
  height: 100%;
  z-index: 1;
  &:after {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    z-index: -999999;
    background: linear-gradient(to bottom, #01592a 11%, #01974a);
  }
}

.openIcon {
  position: fixed;
  top: 15px;
  left: 15px;
  background: #00411e1e;
  padding: 0 3px;
  border-radius: 5px;
  z-index: 4;
}

.wrapLogo {
  padding: 10px 20px;
  text-align: left;
}

#sidenav {
  width: 250px;
  z-index: 5;
  &.openNav {
    left: 0;
  }
}

.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: -260px;
  box-shadow: 6px 6px 10px 0 rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(to bottom, #01592a 11%, #01974a);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 15px;
}

.sidenav .item {
  padding: 8px 8px 8px 32px;
  margin: 10px;
  border-radius: 6px;
  background-image: linear-gradient(to right, #fffadb 0%, #ceb783 100%);
  text-decoration: none;
  font-size: 16px;
  color: #006b35;
  font-weight: bold;
  display: block;
  transition: 0.2s;
  &:active {
    color: rgb(99, 99, 99);
    box-shadow: inset 0 0 2px 0px;
  }
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 26px;
  line-height: 25px;
  width: 25px;
  height: 25px;
  border: 1px solid white;
  color: white;
  border-radius: 100%;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
</style>

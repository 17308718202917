import { mock } from "../../devConfig";
import jwtDecode from "jwt-decode";
import errorCode from "../utils/errorCode";
import store from "../store";
import * as Sentry from "@sentry/vue";

const API_URL = process.env.VUE_APP_API_URL;
// const urlParams = new URLSearchParams(window.location.search);

/* eslint-disable no-unused-vars */
// let to = null,
//   from = null,
//   next = null;
export async function AuthApp(to, from, next) {
  // to = _to;
  // from = _from;
  // next = _next;
  if (window.$authToken) {
    return true;
  }

  const app_access_token = await getCodeToken();
  window.$authToken = await getAppToken(app_access_token);
  console.log("-- set authToken");
  return true;
}

export async function CheckConsent() {
  store.commit("consent/SET_CHECK", true);
}

export async function AuthUser(to, from, next) {
  // check and set mock
  if (process.env.NODE_ENV !== "production") {
    if (mock.apiToken) {
      console.log("-- use mock apiToken");
      console.log("mock.apiToken:", mock.apiToken);
      window.$apiToken = mock.apiToken;
      console.log("-- set api token");

      window.$tokenData = checkTokenData(window.$apiToken);
      console.log("-- check, set token data");

      // await setPermission();
      return true;
    }
  }

  const { access_token, status } = await postAuth({
    channel: "line",
    credential: { accessToken: window.$liffData.accessToken },
  });
  if (status === 401) {
    if (to.matched[0].meta.role === "vansales") {
      next({ path: "/vansales/register" });
      return;
    }

    if (to.matched[0].meta.role === "area.promoter") {
      next({ path: "/area-promoter/register" });
      return;
    }

    next({ path: "/register/welcome" });
    return;
  } else if (status !== 200) {
    return errorCode(101);
  }

  window.$apiToken = access_token;

  console.log("-- set api token");

  window.$tokenData = checkTokenData(window.$apiToken);
  console.log("-- set token data");

  return true;
  // await setPermission();
}

export async function getCodeToken() {
  // const hashToken = getHashValue("access_token");

  // if (hashToken) {
  //     localStorage.setItem('app_access_token', hashToken);
  //     return hashToken
  // }

  // const app_access_token = localStorage.getItem('app_access_token');

  // if (app_access_token) {
  //     localStorage.removeItem('app_access_token');
  //     return app_access_token
  // }

  // window.location = `${API_URL}/api/auth?application=${process.env.VUE_APP_APP_SLUG}&redirectUrl=${encodeURIComponent(window.location.href.split('#')[0])}`

  // create promise for wait redirect and get token on iframe
  return await new Promise((resolve, reject) => {
    const iframe_redirect_auth = document.createElement("iframe");
    iframe_redirect_auth.id = "iframe_redirect_auth";

    window.addEventListener("message", (e) => {
      if (e.data?.target !== "$iframe_redirect_auth") {
        return;
      }

      console.log("iframe_redirect_auth: message", e.data);

      iframe_redirect_auth.remove();

      if (e.data?.access_token) {
        return resolve(e.data?.access_token);
      }

      if (e.data?.error) {
        return reject(errorCode(109));
      }
    });

    // const iframe_redirect_auth = document.getElementById(
    //   "iframe_redirect_auth"
    // );

    iframe_redirect_auth.onload = () => {
      console.log("== iframe_redirect_auth.onload");
      setTimeout(() => {
        return reject(errorCode(110));
      }, 2000);
    };

    iframe_redirect_auth.src = `${API_URL}/api/auth?application=${
      process.env.VUE_APP_APP_SLUG
    }&redirectUrl=${encodeURIComponent(
      window.location.origin + "/getAppCode.html"
    )}`;

    document.body.appendChild(iframe_redirect_auth);

    console.log("-- inject redirect token auth...");
  }).catch((err) => {
    console.error(err);
    if (err.code) throw err;
    throw errorCode(111);
  });
}

export async function setPermission() {
  return true;
}

export async function getAppToken(code) {
  // prevent for some flow case not remove app_access_token
  localStorage.removeItem("app_access_token");

  const res = await fetch(
    `${API_URL}/api/access_token?application=${process.env.VUE_APP_APP_SLUG}&code=${code}`,
    {
      method: "GET",
    }
  );

  if (res.status !== 200) {
    throw errorCode(102);
  }

  const { access_token } = await res.json();

  if (!access_token) {
    throw errorCode(103);
  }

  return access_token;
}

export async function postAuth(reqBody) {
  let result = { access_token: null, status: null, body: {}, error: {} };
  const res = await fetch(`${API_URL}/api/auth`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.$authToken}`,
    },
    body: JSON.stringify(reqBody),
  });

  result.status = res.status;
  result.body = (await res.json()) || {};

  if (res.status === 401) {
    result.error = {
      message: result.body.data?.message || errorCode(108).message,
      code: 108,
    };
    return result;
  }

  if (res.status === 200) {
    if (!result.body.access_token) {
      result.error = errorCode(104);
      return result;
    }
    result.access_token = result.body.access_token;
    return result;
  }

  result.error = {
    message: result.body.data?.message || errorCode(107).message,
    code: 107,
  };

  return result;
}

export function checkTokenData(apiToken) {
  const decode = jwtDecode(apiToken);
  if (decode.u?.id === 0) {
    throw errorCode(105);
  }

  // if (!decode.s?.length > 0) {
  //   throw errorCode(106);
  // }

  if (decode?.u?.id) {
    window["_paq"].push(["setUserId", decode?.u?.id.toString()]);
  }

  setUserSentry(decode.u.id);
  return decode;
}

function setUserSentry(uid) {
  try {
    // set userId tracking
    uid && Sentry.setUser({ id: uid });
  } catch (err) {
    console.error(err);
  }
}

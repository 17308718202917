const state = {
  check: false,
  checked: false,
};

const mutations = {
  SET_CHECK(state, value) {
    state.check = value;
  },
  SET_CHECKED(state, value) {
    state.checked = value;
  },
};

// const actions = {
//     setCheck(com, val) {
//         state.check = val
//     }
// }

export default {
  namespaced: true,
  state,
  //   actions,
  mutations,
};

import { createRouter, createWebHistory } from "vue-router";
// import Home from "../views/Home.vue";
import Middleware from "../middleware/Middleware";
import Tracking from "../utils/Tracking";

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  {
    path: "/register",
    meta: {
      authUser: false,
      checkConsent: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/register/Layout.vue"),
    children: [
      {
        path: "",
        name: "register",
        redirect: "/register/welcome",
      },
      {
        path: "welcome",
        name: "register-welcome",
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register/Welcome.vue"
          ),
      },
      {
        path: "form",
        name: "register-form",
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register/Form.vue"
          ),
      },
      {
        path: "login",
        name: "register-login",
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register/Login.vue"
          ),
      },
      {
        path: "otp",
        name: "register-otp",
        props: (route) => ({
          phone: route.query.phone,
          mode: route.query.mode,
          shopCode: route.query.shopCode,
          passData: route.query.passData,
          routeNameRedirect: route.query.routeNameRedirect,
          title: route.query.title,
        }),
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register/Otp.vue"
          ),
      },
      {
        path: "match",
        name: "register-match",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register/MatchPhone.vue"
          ),
      },
      {
        path: "recheck",
        name: "register-recheck",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register/Recheck.vue"
          ),
      },
      {
        path: "search",
        name: "register-search",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register/Search.vue"
          ),
      },
      {
        path: "list-shop",
        name: "register-list-shop",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register/ListShop.vue"
          ),
      },
      {
        path: "create-shop",
        name: "register-create-shop",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register/CreateShop.vue"
          ),
      },
      {
        path: "success",
        name: "register-success",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../views/register/Success.vue"
          ),
      },
      {
        path: "/change-phone/form",
        name: "change-phone",
        props: (route) => ({
          shopCode: route.query.shopCode,
          oldPhone: route.query.oldPhone,
        }),
        // props: true,
        component: () =>
          import(
            /* webpackChunkName: "change-phone" */ "../views/register/ChangePhoneForm.vue"
          ),
      },
      {
        path: "/change-phone/submit",
        name: "change-phone-submit",
        props: (route) => ({
          shopCode: route.query.shopCode,
          oldPhone: route.query.oldPhone,
          phone: route.query.phone,
          otpRefCode: route.query.otpRefCode,
        }),
        component: () =>
          import(
            /* webpackChunkName: "change-phone" */ "../views/register/ChangePhoneSubmit.vue"
          ),
      },
    ],
  },
  {
    path: "/retails-map",
    name: "retails-map",
    props: (route) => ({
      agentCode: route.query.agentCode,
      parentCode: route.query.parentCode,
      parentName: route.query.parentName,
    }),
    component: () =>
      import(/* webpackChunkName: "retails-map" */ "../views/RetailsByMap.vue"),
    meta: {
      // layout: "navbar",
    },
  },
  {
    path: "/profile",
    name: "profile",
    props: true,
    meta: {
      layout: "SidebarLayout",
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
  },
  {
    path: "/update-profile",
    name: "update-profile",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "update-profile" */ "../views/UpdateProfile.vue"
      ),
  },
  {
    path: "/shop-qr",
    name: "shop-qr",
    props: true,
    meta: {
      layout: "SidebarLayout",
    },
    component: () =>
      import(/* webpackChunkName: "shop-qr" */ "../views/ShopQR.vue"),
  },
  {
    path: "/shop-change-phone",
    name: "shop-change-phone",
    component: () =>
      import(
        /* webpackChunkName: "shop-change-phone" */ "../views/ShopChangePhone.vue"
      ),
  },
  {
    path: "/vansales",
    name: "vansales",
    meta: {
      role: "vansales",
    },
    component: () =>
      import(/* webpackChunkName: "vansales" */ "../views/vansales/Layout.vue"),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "vansales" */ "../views/vansales/Home.vue"
          ),
      },
      {
        path: "register",
        meta: {
          authUser: false,
          checkConsent: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "vansales-register" */ "../views/vansales/register/Layout.vue"
          ),
        children: [
          {
            path: "",
            name: "vansales-register",
            redirect: "/vansales/register/form",
          },
          {
            path: "form",
            name: "vansales-register-form",
            props: true,
            meta: {},
            component: () =>
              import(
                /* webpackChunkName: "vansales-register" */ "../views/vansales/register/Form.vue"
              ),
          },
          {
            path: "auth",
            name: "vansales-register-auth",
            props: true,
            meta: {},
            component: () =>
              import(
                /* webpackChunkName: "vansales-register" */ "../views/vansales/register/Auth.vue"
              ),
          },
          {
            path: "success",
            name: "vansales-register-success",
            props: true,
            meta: {},
            component: () =>
              import(
                /* webpackChunkName: "vansales-register" */ "../views/vansales/register/Success.vue"
              ),
          },
        ],
      },
      {
        path: "qr",
        name: "vansales-qr",
        props: true,
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(/* webpackChunkName: "vansale-qr" */ "../views/ShopQR.vue"),
      },
      {
        path: "profile",
        name: "vansales-profile",
        props: true,
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(
            /* webpackChunkName: "vansales-profile" */ "../views/vansales/Profile.vue"
          ),
      },
      {
        path: "verify-shop",
        name: "vansales-verify-shop",
        props: {
          default: true,
          qrRouteName: "vansales-qr",
          recheckRouteName: "vansales-recheck-shop",
        },
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(
            /* webpackChunkName: "vansales-verify-shop" */ "../views/VerifyShop.vue"
          ),
      },
      {
        path: "recheck-shop/:shopId",
        name: "vansales-recheck-shop",
        props: (route) => ({
          alertSuccess: true,
          redirectRouteName: "vansales-verify-shop-success",
          shopId: route.params.shopId,
          verifyShop: true,
        }),
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(
            /* webpackChunkName: "vansales-verify-shop" */ "../views/UpdateProfile.vue"
          ),
      },
      {
        path: "verify-shop-success",
        name: "vansales-verify-shop-success",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "vansales-verify-shop" */ "../views/vansales/VerifyShopSuccess.vue"
          ),
      },
      {
        path: "shops",
        name: "vansales-shops",
        props: true,
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(
            /* webpackChunkName: "vansales-shops" */ "../views/ListShop.vue"
          ),
      },
      {
        path: "update-shop/:shopId",
        name: "vansales-update-shop",
        props: (route) => ({
          alertSuccess: true,
          shopId: route.params.shopId,
        }),
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(
            /* webpackChunkName: "vansales-update-shop" */ "../views/UpdateProfile.vue"
          ),
      },
    ],
  },
  {
    path: "/area-promoter",
    name: "area-promoter",
    meta: {
      role: "area.promoter",
    },
    component: () =>
      import(
        /* webpackChunkName: "area-promoter" */ "../views/area-promoter/Layout.vue"
      ),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "area-promoter" */ "../views/area-promoter/Home.vue"
          ),
      },
      {
        path: "register",
        meta: {
          authUser: false,
          checkConsent: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "area-promoter-register" */ "../views/area-promoter/register/Layout.vue"
          ),
        children: [
          {
            path: "",
            name: "area-promoter-register",
            redirect: "/area-promoter/register/form",
          },
          {
            path: "form",
            name: "area-promoter-register-form",
            props: true,
            meta: {},
            component: () =>
              import(
                /* webpackChunkName: "area-promoter-register" */ "../views/area-promoter/register/Form.vue"
              ),
          },
          {
            path: "auth",
            name: "area-promoter-register-auth",
            props: true,
            meta: {},
            component: () =>
              import(
                /* webpackChunkName: "area-promoter-register" */ "../views/area-promoter/register/Auth.vue"
              ),
          },
          {
            path: "success",
            name: "area-promoter-register-success",
            props: true,
            meta: {},
            component: () =>
              import(
                /* webpackChunkName: "area-promoter-register" */ "../views/area-promoter/register/Success.vue"
              ),
          },
        ],
      },
      {
        path: "qr",
        name: "area-promoter-qr",
        props: true,
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(
            /* webpackChunkName: "area-promoter-qr" */ "../views/ShopQR.vue"
          ),
      },
      {
        path: "profile",
        name: "area-promoter-profile",
        props: true,
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(
            /* webpackChunkName: "area-promoter-profile" */ "../views/area-promoter/Profile.vue"
          ),
      },
      {
        path: "verify-shop",
        name: "area-promoter-verify-shop",
        props: {
          default: true,
          qrRouteName: "area-promoter-qr",
          recheckRouteName: "area-promoter-recheck-shop",
        },
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(
            /* webpackChunkName: "area-promoter-verify-shop" */ "../views/VerifyShop.vue"
          ),
      },
      {
        path: "recheck-shop/:shopId",
        name: "area-promoter-recheck-shop",
        props: (route) => ({
          alertSuccess: true,
          redirectRouteName: "area-promoter-verify-shop-success",
          shopId: route.params.shopId,
          verifyShop: true,
        }),
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(
            /* webpackChunkName: "area-promoter-verify-shop" */ "../views/UpdateProfile.vue"
          ),
      },
      {
        path: "verify-shop-success",
        name: "area-promoter-verify-shop-success",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "area-promoter-verify-shop" */ "../views/area-promoter/VerifyShopSuccess.vue"
          ),
      },
      {
        path: "shops",
        name: "area-promoter-shops",
        props: true,
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(
            /* webpackChunkName: "area-promoter-shops" */ "../views/ListShop.vue"
          ),
      },
      {
        path: "update-shop/:shopId",
        name: "area-promoter-update-shop",
        props: (route) => ({
          alertSuccess: true,
          shopId: route.params.shopId,
        }),
        meta: {
          layout: "SidebarLayout",
        },
        component: () =>
          import(
            /* webpackChunkName: "area-promoter-update-shop" */ "../views/UpdateProfile.vue"
          ),
      },
    ],
  },
  {
    path: "/verify-shop/:shopId",
    name: "verify-shop",
    props: (route) => ({
      shopId: route.params.shopId,
    }),
    component: () =>
      import(
        /* webpackChunkName: "verify-shop" */ "../views/DirectVerifyShop.vue"
      ),
  },
  {
    path: "/shop/:shopId",
    name: "shop-profile",
    props: (route) => ({
      shopId: route.params.shopId,
    }),
    meta: {
      layout: "SidebarLayout",
    },
    component: () =>
      import(/* webpackChunkName: "shop-profile" */ "../views/Profile.vue"),
  },
  {
    path: "/shop/:shopId/edit",
    name: "shop-edit",
    props: (route) => ({
      alertSuccess: true,
      shopId: route.params.shopId,
    }),
    meta: {
      layout: "SidebarLayout",
    },
    component: () =>
      import(/* webpackChunkName: "shop-edit" */ "../views/UpdateProfile.vue"),
  },
  {
    path: "/retail/contact-us",
    name: "retail-contact-us",
    meta: {
      layout: "SidebarLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "retail-contact-us" */ "../views/RetailContactUs.vue"
      ),
  },
  {
    path: "/list-contact-us",
    name: "list-contact-us",
    meta: {
      layout: "SidebarLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "list-contact-us" */ "../views/ListContactUs.vue"
      ),
  },
  {
    path: "/user-consents",
    name: "user-consents",
    meta: {
      layout: "SidebarLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "user-consents" */ "../views/UserConsents.vue"
      ),
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: () =>
      import(
        /* webpackChunkName: "campaigns" */ "../views/campaigns/ListCampaigns.vue"
      ),
  },
  {
    path: "/stamps",
    name: "stamps",
    meta: {
      layout: "SidebarLayout",
    },
    component: () =>
      import(/* webpackChunkName: "stamps" */ "../views/stamps/Stamps.vue"),
  },
  {
    path: "/review-stamps",
    meta: {
      layout: "SidebarLayout",
    },
    component: () =>
      import(
        /* webpackChunkName: "review-stamps" */ "../views/stamps/ReviewStampsLayout.vue"
      ),
    children: [
      {
        path: "",
        name: "review-stamps",
        redirect: "/review-stamps/main",
      },
      {
        path: "main",
        name: "review-stamps-main",
        component: () =>
          import(
            /* webpackChunkName: "review-stamps" */ "../views/stamps/ReviewStamps.vue"
          ),
      },
      {
        path: "reward",
        name: "review-stamps-reward",
        component: () =>
          import(
            /* webpackChunkName: "review-stamps" */ "../views/stamps/ReviewStamps.vue"
          ),
      },
    ],
  },
  {
    path: "/stamp",
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "stamp-layout" */ "../views/stamps/StampLayout.vue"
      ),
    children: [
      {
        path: "",
        redirect: "/stamp/missions",
        name: "stamp",
      },
      {
        path: "missions",
        name: "stamp-missions",
        component: () =>
          import(
            /* webpackChunkName: "stamp-missions" */ "../views/stamps/Missions.vue"
          ),
      },
      {
        path: "rewards",
        name: "stamp-rewards",
        component: () =>
          import(
            /* webpackChunkName: "stamp-rewards" */ "../views/stamps/Rewards.vue"
          ),
      },
      {
        path: "history",
        name: "stamp-history",
        component: () =>
          import(
            /* webpackChunkName: "stamp-rewards" */ "../views/stamps/History.vue"
          ),
      },
    ],
  },
  {
    path: "/stamp/history-index",
    name: "stamp-history-index",
    component: () =>
      import(
        /* webpackChunkName: "stamp-history-index" */ "../views/stamps/HistoryIndex.vue"
      ),
  },
  {
    path: "/stamp/get-reward",
    name: "stamp-get-reward",
    component: () =>
      import(
        /* webpackChunkName: "stamp-rewards" */ "../views/stamps/GetReward.vue"
      ),
  },
  {
    path: "/stamp/mission",
    name: "stamp-mission",
    redirect: "/stamp/mission/status",
  },
  {
    path: "/stamp/mission/join",
    name: "stamp-mission-join",
    component: () =>
      import(
        /* webpackChunkName: "stamp-mission-join" */ "../views/stamps/MissionJoin.vue"
      ),
  },
  {
    path: "/stamp/mission/status",
    name: "stamp-mission-status",
    component: () =>
      import(
        /* webpackChunkName: "stamp-mission-status" */ "../views/stamps/MissionStatus.vue"
      ),
  },
  {
    path: "/review-missions",
    name: "review-missions",
    component: () =>
      import(
        /* webpackChunkName: "review-missions" */ "../views/stamps/ReviewMissions.vue"
      ),
  },
  {
    path: "/review-mission",
    component: () =>
      import(
        /* webpackChunkName: "review-mission" */ "../views/stamps/ReviewMissionLayout.vue"
      ),
    children: [
      {
        path: "",
        redirect: "/review-mission/waiting",
        name: "review-mission",
      },
      {
        path: "waiting",
        name: "review-mission-waiting",
        component: () =>
          import(
            /* webpackChunkName: "review-mission" */ "../views/stamps/ReviewMissionWaiting.vue"
          ),
      },
      {
        path: "waiting-region",
        name: "review-mission-waiting-region",
        component: () =>
          import(
            /* webpackChunkName: "review-mission" */ "../views/stamps/ReviewMissionWaitingRegion.vue"
          ),
      },
      {
        path: "approved",
        name: "review-mission-approved",
        component: () =>
          import(
            /* webpackChunkName: "review-mission" */ "../views/stamps/ReviewMissionApproved.vue"
          ),
      },
      {
        path: "rejected",
        name: "review-mission-rejected",
        component: () =>
          import(
            /* webpackChunkName: "review-mission" */ "../views/stamps/ReviewMissionRejected.vue"
          ),
      },
    ],
  },
  {
    path: "/review-mission-join",
    name: "review-mission-join",
    component: () =>
      import(
        /* webpackChunkName: "review-mission-join" */ "../views/stamps/ReviewMissionJoin.vue"
      ),
  },
  {
    path: "/review-stamp-reward",
    component: () =>
      import(
        /* webpackChunkName: "review-stamp-reward" */ "../views/stamps/ReviewStampRewardLayout.vue"
      ),
    children: [
      {
        path: "",
        redirect: "/review-stamp-reward/to-ship",
        name: "review-stamp-reward",
      },
      {
        path: "to-ship",
        name: "review-stamp-reward-to-ship",
        component: () =>
          import(
            /* webpackChunkName: "review-stamp-reward" */ "../views/stamps/ReviewStampRewardToShip.vue"
          ),
      },
      {
        path: "to-receive",
        name: "review-stamp-reward-to-receive",
        component: () =>
          import(
            /* webpackChunkName: "review-stamp-reward" */ "../views/stamps/ReviewStampRewardToReceive.vue"
          ),
      },
      {
        path: "completed",
        name: "review-stamp-reward-completed",
        component: () =>
          import(
            /* webpackChunkName: "review-stamp-reward" */ "../views/stamps/ReviewStampRewardComplete.vue"
          ),
      },
    ],
  },
  {
    path: "/review-stamp-shop-rewards",
    name: "review-stamp-shop-rewards",
    component: () =>
      import(
        /* webpackChunkName: "review-stamp-shop-rewards" */ "../views/stamps/ReviewStampShopRewards.vue"
      ),
  },
  {
    path: "/review-rewarded",
    name: "review-rewarded",
    component: () =>
      import(
        /* webpackChunkName: "review-reward" */ "../views/stamps/ReviewRewarded.vue"
      ),
  },
  {
    path: "/sale-ordering",
    name: "sale-ordering",
    component: () =>
      import(
        /* webpackChunkName: "sale-ordering" */ "../views/SaleOrdering.vue"
      ),
    meta: {
      checkConsent: false,
    },
  },
  {
    path: "/events",
    name: "events",
    meta: {
      layout: "SidebarLayout",
    },
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Events.vue"),
  },
  {
    path: "/my-coupons",
    name: "my-coupons",
    meta: {},
    props: {
      myCoupon: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "my-coupons" */ "../views/coupon/Coupons.vue"
      ),
  },
  {
    path: "/coupons",
    name: "coupons",
    meta: {},
    component: () =>
      import(/* webpackChunkName: "coupons" */ "../views/coupon/Coupons.vue"),
  },
  {
    path: "/coupon/:privilegeId",
    props: true,
    name: "coupon",
    meta: {},
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../views/coupon/Coupon.vue"),
  },
  {
    path: "/my-coupon/:couponId",
    props: true,
    name: "my-coupon",
    meta: {},
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../views/coupon/Coupon.vue"),
  },
  {
    path: "/point-transactions",
    props: true,
    name: "point-transactions",
    meta: {},
    component: () =>
      import(
        /* webpackChunkName: "pointTransactions" */ "../views/point/PointTransactions.vue"
      ),
  },
  {
    path: "/rewards",
    name: "rewards",
    component: () =>
      import(/* webpackChunkName: "rewards" */ "../views/reward/Rewards.vue"),
  },
  {
    path: "/reward/:ppId",
    props: true,
    name: "reward",
    component: () =>
      import(/* webpackChunkName: "reward" */ "../views/reward/Reward.vue"),
  },
  {
    path: "/coming-soon",
    name: "coming-soon",
    meta: {
      authApp: false,
      authLiff: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "coming-soon" */ "../views/ComingSoonPage.vue"
      ),
  },
  {
    path: "/error",
    name: "error",
    props: true,
    meta: {
      authApp: false,
      authLiff: false,
    },
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/ErrorPage.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "notfound",
    meta: {
      authApp: false,
    },
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/NotFoundPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  console.log(to, from);
  window.$prevRoute = from;

  if (!to.matched.length) {
    return next({ name: "notfound" });
  }

  await Middleware(to, from, next);

  window.loading(false);
});

router.afterEach((to) => {
  Tracking(to.name, to.fullPath);
});

export default router;

// import Auth from "./Auth";
import AuthLiff from "../utils/AuthLiff";
import { AuthApp, AuthUser, CheckConsent } from "@/middleware/Auth";

export default async function (to, from, next) {
  window.loading(true);
  try {
    let pass = true;

    // if (to.meta.auth !== false && !window.$apiToken) {
    //     // getCodeToken first time for save to localStorage before liff init redirect
    //     await getCodeToken();
    //     console.log("-- set codeToken");
    // }

    // this root route ignore liff ?
    if (to.matched[0].meta.authLiff !== false) {
      await AuthLiff();
    }

    if (to.meta.authApp !== false && to.matched[0].meta.authApp !== false) {
      if (!window.$apiToken) {
        pass = await AuthApp(to, from, next);

        if (
          to.meta.authUser !== false &&
          to.matched[0].meta.authUser !== false
        ) {
          pass = await AuthUser(to, from, next);

          if (
            pass === true &&
            to.meta.checkConsent !== false &&
            to.matched[0].meta.checkConsent !== false
          ) {
            await CheckConsent();
          }
        }
      } else {
        pass = true;
      }
    }

    if (pass === true) {
      return next();
    } else {
      next({ name: "error", params: pass, replace: true });
    }
  } catch (error) {
    console.error(error);
    return next({ name: "error", params: error, replace: true });
  } finally {
    window.loading(false);
  }
}

<template>
  <div class="defaultLayout">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.defaultLayout {
  height: 100%;
}
</style>

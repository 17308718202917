import config from "../../liffConfig";
import errorCode from "./errorCode";

const liff = window.liff;

async function setData() {
  const profile = await liff.getProfile();
  const accessToken = await liff.getAccessToken();

  if ((!profile, !accessToken)) {
    throw errorCode(302);
  }

  window.$liffData = { profile, accessToken };

  return;
}

function login() {
  // resolve after logged
  return new Promise((resolve) => {
    if (!liff.isLoggedIn()) {
      if (!liff.isInClient()) {
        console.log("ex browser");
        console.log("login...");
        console.log("redirect url:", window.location.href);
        liff.login({
          redirectUri: window.location.href,
        });
        // wait for redirect login
      }
    } else {
      console.log("liff login resolve");
      resolve();
    }
  });
}

export default async function AuthLiff() {
  if (window.$liffData) return;

  // if mock mode
  if (config.mockLiffMode) {
    console.log("use liff mock data");
    window.$liffData = config.mockData;
    return;
  }

  const liffId = process.env.VUE_APP_LIFF_ID_ROOT;
  if (!liffId) {
    throw errorCode(301);
  }

  console.log("init liff...");
  await liff.init({
    liffId: liffId,
  });

  await liff.ready;
  await login();
  await setData();
}

import { merge } from "lodash";

const state = {
  phone: "",
  otpRefCode: "",
  shopsSearch: [],
  shopSelected: {},
  isMatchPhone: false,
  form: {
    prefillId: 0,
    gender: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    citizenId: "",
    birthDate: null,
    shop: {
      typeId: 0,
      code: "",
      name: "",
      address: "",
      subDistrictId: null,
      districtId: null,
      provinceId: null,
      regionId: null,
      postcode: "",
      subDistrict: "",
      district: "",
      province: "",
    },
    utm: {},
    authChannel: "phone",
    AuthCredential: {
      otpRefCode: "",
    },
    link: {
      channel: "line",
      accessToken: "",
    },
    consents: [],
  },
};

const mutations = {
  SET_SHOPS_SEARCH(state, value) {
    state.shopsSearch = value;
  },
  SET_SHOP_SELECTED(state, value) {
    state.shopSelected = value;
  },
  SET_PHONE(state, value) {
    state.phone = value;
    state.form.phone = value;
  },
  SET_OTP_REF_CODE(state, value) {
    state.otpRefCode = value;
    state.form.AuthCredential.otpRefCode = value;
  },
  SET_FORM_SHOP(state, value) {
    state.form.shop = value;
  },
  SET_FORM(state, value) {
    state.form = value;
  },
  SET_LINK_LINE_TOKEN(state, value) {
    state.form.link.accessToken = value;
  },
  SET_IS_MATCH_PHONE(state, value) {
    state.isMatchPhone = value;
  },
  SET_CONSENTS_RESULT(state, value) {
    state.form.consents = value;
  },
  SET_UTM(state, value) {
    state.form.utm = value;
  },
};

const actions = {
  setPrefilSelected({ commit }, value) {
    commit("SET_SHOP_SELECTED", value);
    const {
      code,
      name,
      address,
      regionId,
      postcode,
      province,
      district,
      subDistrict,
    } = value;

    const form = {
      prefillId: value.id,
      shop: {
        code,
        name,
        address,
        subDistrictId: subDistrict?.id,
        districtId: district?.id,
        provinceId: province?.id,
        subDistrict: subDistrict?.name,
        district: district?.name,
        province: province?.name,
        regionId,
        postcode,
      },
    };

    commit("SET_FORM", merge(state.form, form));
  },
  clearShopSelect({ commit }) {
    const defaultForm = {
      prefillId: 0,
      gender: null,
      firstName: "",
      lastName: "",
      email: "",
      phone: state.phone,
      citizenId: "",
      birthDate: null,
      shop: {
        typeId: 0,
        code: "",
        name: "",
        address: "",
        subDistrictId: null,
        districtId: null,
        provinceId: null,
        regionId: null,
        postcode: "",
        subDistrict: "",
        district: "",
        province: "",
      },
    };
    commit("SET_FORM", merge(state.form, defaultForm));
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

const envUseLiff = ["production", "uat", "dev"]; // add 'development' for test real liff in local (must be ready line service)
export default {
  mockLiffMode: !envUseLiff.includes(process.env.VUE_APP_MODE),
  mockData: {
    profile: {
      displayName: "devdev",
      pictureUrl: "https://i.imgur.com/xPyGDjE.jpg",
      statusMessage: "it good day",
      userId: "TEST_LIFF_USERID",
    },
    accessToken: "",
  },
};
